import React, { useState, useEffect, useContext, useRef } from 'react'
import { useReactToPrint } from 'react-to-print';
import { Card, Text, Box, ActionIcon, Button, Avatar, Flex, Title, Group } from '@mantine/core';
import { PiTrashDuotone, PiPrinterDuotone, PiChairDuotone, PiBagDuotone, PiMotorcycleDuotone,PiArrowLeftDuotone } from 'react-icons/pi'
import { useNavigate, useParams } from 'react-router-dom';
import UberIcon from '../../assets/icons/uberIcon';
import RappiIcon from '../../assets/icons/RappiIcon';
import DidiIcon from '../../assets/icons/DidiIcon';
import OrderResume from '../../components/Pos/OrderResume';
import OrderSummary from '../../components/Pos/OrderSummary';
import AmountSummary from '../../components/Pos/AmountSummary';
import { get_order } from '../../utils/apis/orders'
import { localOrder } from '../../utils/functions/generateLocalCart';
import { getTotal, getIva } from '../../utils/functions/getTotal';
import { post_transaction } from '../../utils/apis/transactions';
import AlertContext from '../../contexts/Alert/alertContext';
import { AlertaPermisos } from '../../components/Alerta/AlertaPermisos';
import QrOrden from '../../components/Modal/QrOrden';
import Ticket from '../../components/Pos/Ticket';
import { obtenerIniciales } from '../../utils/functions/generateInitNames';

const ComponentToPrint = React.forwardRef((props, ref) =>
{
  return (
    <div ref={ ref }>
      <Ticket order={ props.order } orderDetail={ props.orderDetail } orderInfo={ props.orderInfo } id={ props.id } />

    </div>
  );
});
const PosDetail = () =>
{
  const alertContext = useContext(AlertContext);
  const { alerta, showAlert } = alertContext;
  const navigate = useNavigate();
  let { id } = useParams();
  const [ showQr, setShowQr ] = useState(false);
  const [ order, setOrder ] = useState([]);
  const [ paymentType, setPaymentType ] = useState('efectivo')
  const [ orderDetail, setOrderDetail ] = useState(null)
  const [ orderInfo, setOrderInfo ] = useState({
    table: '',
    persons: '',
    name: '',
    phone: '',
    pickUpDate: '',
    pickUpTime: '',
    address: ''
  });
  const [ subTotal, setSubtotal ] = useState(0.00);
  const [ ivaAmount, setIvaAmount ] = useState(0.00);
  const [ total, setTotal ] = useState(0.00);
  const [ desc, setDesc ] = useState(0);
  const [ moneyChange, setMoneyChange ] = useState(0);
  const [ totalMoneyChange, setTotalMoneyChange ] = useState(0);

  const calcSummary = (items) =>
  {
    const orderTotal = getTotal(items);
    const ivaAmount = getIva(orderTotal)
    setSubtotal(orderTotal.toFixed(2));
    setIvaAmount(ivaAmount);
    setTotal(orderTotal.toFixed(2))
  }

  const calcSummaryDesc = (items, desc) =>
  {
    const orderTotal = getTotal(items);
    const percentage = 1 - (Number(desc) / 100);
    if (desc <= 100) {
      const ivaAmount = getIva((orderTotal * percentage))
      setIvaAmount(ivaAmount);
      setTotal((orderTotal * percentage).toFixed(2))
      if (moneyChange !== 0) {
        setTotalMoneyChange(Number(moneyChange) - Number(total))
      }
    } else {
      showAlert('Ocurrio Algo', 'El descuento no puede ser mayor al total', 'info')
    }
  }

  const calculateChange = (value) =>
  {
    setMoneyChange(value)
    setTotalMoneyChange(Number(value) - Number(total))
  }

  useEffect(() =>
  {
    if (paymentType === 'tarjeta') {
      setMoneyChange(0);
      setTotalMoneyChange(0);
    }
  }, [ paymentType ])

  const getOrder = async (id) =>
  {
    const res = await get_order(id);
    console.log(res.results)
    setOrderDetail(res.results)
    setOrderInfo(res.results.orderInfo);
    const items = await localOrder(res.results.orderProducts);
    setOrder(items);
    calcSummary(items)

  }

  const goBack = () =>
  {
    navigate(-1);
  }
  useEffect(() =>
  {
    getOrder(id);

  }, [ id ]);

  useEffect(() =>
  {
    calcSummaryDesc(order, desc)
  }, [ desc ])

  const addTransaction = async () =>
  {
    const data = {
      orderInfo: orderDetail.orderInfo,
      status: 'paid',
      orderType: orderDetail.orderType,
      orderProducts: order,
      methodPayment: paymentType,
      subtotal: subTotal,
      tax: ivaAmount,
      discount: desc,
      moneyPayment: moneyChange,
      moneyChange: totalMoneyChange,
      total: total,
      dateOrder: orderDetail.date,
      orderId: orderDetail._id,
      waiter: orderDetail.user._id
    }

    const res = await post_transaction(data);
    if (res.msg === 'done') {
      goBack()
    }
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () =>
    {
      const current = componentRef.current;
      if (current) {
        current.style.marginBottom = '20mm'; // Ajuste dinámico
      }
      return current;
    },
  });

  return (
    <>
      <div className='flex flex-row items-center justify-between gap-2'>
        <div className='flex flex-row items-center justify-start gap-2' onClick={ () => goBack() } >
          <ActionIcon size="lg" color="gray" variant="subtle" aria-label='Search database'>
            <PiArrowLeftDuotone className='text-xl' />
          </ActionIcon>
          <Title order={ 1 }>Regresar</Title>
        </div>
        <Button color='blue' variant='light' className='w-full sm:w-auto' onClick={ () => setShowQr(!showQr) }>
          <Text>Mostar QR</Text>
        </Button>
        {/* <ComponentToPrint className="hidden mb-4" ref={componentRef} order={order} orderDetail={orderDetail} orderInfo={orderInfo} id={id} total={total} subTotal={subTotal} desc={desc} ivaAmount={ivaAmount}/>
          <Button colorScheme='blue' variant='ghost' className='w-full sm:w-auto' onClick={handlePrint}>
            <Text>Imprimir</Text>
          </Button> */}
      </div>
      <Card withBorder shadow='md' my="md" radius="xl">
        <Box className='flex flex-row flex-wrap justify-between'>
          <Box className='flex flex-row items-center'>
            { orderDetail?.orderType === 'local' && <PiChairDuotone className='text-xl' /> }
            { orderDetail?.orderType === 'delivery' && <PiMotorcycleDuotone className='text-xl' /> }
            { orderDetail?.orderType === 'pickup' && <PiBagDuotone className='text-xl' /> }
            { orderDetail?.orderType === 'uberEats' && <UberIcon className='w-8 h-8' /> }
            { orderDetail?.orderType === 'rappi' && <RappiIcon className='w-10 h-10' /> }
            { orderDetail?.orderType === 'didi' && <DidiIcon className='w-10 h-10' /> }
            { orderDetail?.orderType === 'local' && <Text fz="md" mx="sm" >{ orderInfo.table }</Text> }
            { orderDetail?.orderType === 'delivery' && <Text fz="md" mx="sm" >A domicilio</Text> }
            { orderDetail?.orderType === 'pickup' && <Text fz="md" mx="sm" >Para llevar</Text> }
          </Box>
          <Box className='flex flex-row items-center justify-between gap-1'>
            <Text fz="sm">Atendido por:</Text>
            <Group>
              <Avatar color="indigo" size='md' >
                {orderDetail?.user?.name && obtenerIniciales(orderDetail?.user?.name) }
              </Avatar>
              <Text fz="md">{ orderDetail?.user?.name }</Text>
            </Group>
          </Box>
        </Box>
        <Box className='flex flex-row'>
          <Text fz="md" >Pedido</Text>
          <Text fz="md" mx="md" >#{ id }</Text>
        </Box>

      </Card>
      <Flex direction={ { base: 'column-reverse', md: "row" } } align="stretch" gap="md" className='w-full'>
        <Box className='w-full' >
          <OrderResume order={ order } />

          <AmountSummary
            subTotal={ subTotal }
            ivaAmount={ ivaAmount }
            total={ total }
            setDesc={ setDesc }
            desc={ desc }
            addTransaction={ addTransaction }
            goBack={ goBack }
            paymentType={ paymentType }
            totalMoneyChange={ totalMoneyChange }
            moneyChange={ moneyChange }
            calculateChange={ calculateChange }
          />
        </Box>
        <Box className='w-12/12 md:w-6/12 lg:w-4/12 flex flex-col items-stretch'>
          <OrderSummary orderInfo={ orderInfo } orderDetail={ orderDetail } />
          <Card withBorder shadow='md' my="md" radius="xl">
            <Text className='text-xl font-bold mb-3'>Forma de pago</Text>
            <Flex gap="md" justify="center">

              <Button fullWidth color='blue' size='md' radius={ 'md' } variant={ paymentType === 'efectivo' ? 'solid' : 'outline' } width='100%' onClick={ () => setPaymentType('efectivo') }>
                Efectivo
              </Button>
              <Button fullWidth color='blue' size='md' radius={ 'md' } variant={ paymentType === 'tarjeta' ? 'solid' : 'outline' } width='100%' onClick={ () => setPaymentType('tarjeta') }>
                Tarjeta
              </Button>

            </Flex>

          </Card>
        </Box>

      </Flex>
      { alerta && <AlertaPermisos alerta={ alerta } /> }
      { showQr && <QrOrden show={ showQr } setShow={ setShowQr } orderId={ orderDetail._id } /> }
    </>
  )
}

export default PosDetail