import { map, size, filter } from 'lodash'
import { CART } from '../constants'
  const token =  localStorage.getItem('token')
  
export const get_all_orders = async (data) => {
    
    try {
        const url = `${process.env.REACT_APP_API_URL}/api/v1/orders`;
        const params = {
            method: 'GET',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json()
        console.log(result)
        return result

    } catch (error) {
        console.log(error)
        return null
    }
}

export const get_order = async (id) => {
    
    try {
        const url = `${process.env.REACT_APP_API_URL}/api/v1/orders/${id}`;
        const params = {
            method: 'GET',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json()
        return result

    } catch (error) {
        console.log(error)
        return null
    }
}

export const post_order = async (data) => {
    
    try {
        console.log('TOKEN, ', token)
        const url = `${process.env.REACT_APP_API_URL}/api/v1/orders`;
        const params = {
            method: 'POST',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": `Bearer ${token}`
            },
            body:JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json()
        return result

    } catch (error) {
        console.log(error)
        return null
    }
}

export const update_order = async (id,data) => {
    
    try {
        const url = `${process.env.REACT_APP_API_URL}/api/v1/orders/${id}`;
        const params = {
            method: 'PATCH',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": `Bearer ${token}`
            },
            body:JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json()
        return result

    } catch (error) {
        console.log(error)
        return null
    }
}

export const cancel_order = async (id ,data) => {
    
    try {
        const url = `${process.env.REACT_APP_API_URL}/api/v1/orders/${id}`;
        const params = {
            method: 'DELETE',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": `Bearer ${token}`
            },
            body:JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json()
        return result

    } catch (error) {
        console.log(error)
        return null
    }
}

export async function getProductCartApi () {
    try {
        const cart = await localStorage.getItem(CART);
        if (!cart) return [];
        else return JSON.parse(cart)
    } catch (error) {
        return null
    }
}

export async function addProductCartApi (id, _id, quantity) {

    try {
        const cart = await getProductCartApi();
        if (!cart) throw new Error("Error al obtener el carrito");

        if (size(cart) === 0) {
            cart.push({
                id,
                _id,
                statusProductOrder:false,
                quantity,
            })
        } else {
            let found = false;
            map(cart, (product) => {
                if (product.id === id) {
                    product.quantity += quantity
                    found = true;
                    return product;
                }
            });
            if (!found) {
                cart.push({
                    id,
                    _id,
                    statusProductOrder:false,
                    quantity,

                });
            }
        }
        await localStorage.setItem(CART, JSON.stringify(cart));
        return true;
    } catch (error) {
        return false
    }

}

export async function addProductNote (id, note) {

    try {
        const cart = await getProductCartApi();
        map(cart, (product) => {
            
            if (product.id === id) {
                return (product.note = note)
            }
        })

        await localStorage.setItem(CART, JSON.stringify(cart))
        return true
    } catch (error) {
        return null
    }

}

export async function markAsCourtesy (id) {

    try {
        const cart = await getProductCartApi();
        map(cart, (product) => {
            
            if (product.id === id) {
                return (product.isCourtesy = !product.isCourtesy)
            }
        })

        await localStorage.setItem(CART, JSON.stringify(cart))
        return true
    } catch (error) {
        return null
    }

}

export async function deleteProductCartApi (id) {
    try {
        const cart = await getProductCartApi();
        const newCart = filter(cart, (product) => {
            return product.id !== id;
        })
        await localStorage.setItem(CART, JSON.stringify(newCart))
        return true
    } catch (error) {
        return null
    }
}

// export async function clearProductCartApi () {
//     try {
//         await localStorage.removeItem(CART)
//         return true
//     } catch (error) {
//         return null
//     }
// }

export async function increaseProductCartApi (id) {
    try {
        const cart = await getProductCartApi();
        map(cart, (product) => {
            
            if (product.id === id) {
                return (product.quantity += 1)
            }
        })

        await localStorage.setItem(CART, JSON.stringify(cart))
        return true
    } catch (error) {
        return null
    }

}

export async function decreaseProductCartApi (id) {
    let isDelete = false
    try {
        const cart = await getProductCartApi();
        map(cart, (product) => {
            if (product.id === id) {
                if (product.quantity === 1) {
                    isDelete = true
                    return null
                } else {
                    return (product.quantity -= 1)
                }

            }
        })

        if (isDelete) {
            await deleteProductCartApi(id)
        } else {
            await localStorage.setItem(CART, JSON.stringify(cart))
        }


        return true
    } catch (error) {
        return null
    }

}