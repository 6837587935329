import React, { useContext, useEffect } from 'react'
import md5 from 'md5';
import Logo from '../../assets/Logo.png'
import { Button, TextInput, PasswordInput, Card, Box, Alert, Text, Title, NavLink, Flex } from '@mantine/core';
import { PiInfoDuotone } from 'react-icons/pi'
import { Link, useNavigate } from 'react-router-dom'
import AuthContext from '../../contexts/Auth/authContext';
import AlertContext from '../../contexts/Alert/alertContext';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import
{
    RESTORE_PASSWORD,
} from '../../routes/types'
import ToggleButton from '../../components/Header/ToggleButton';

const Login = () =>
{
    const navigate = useNavigate();
    const alertContext = useContext(AlertContext);
    const { alerta, showAlert } = alertContext;
    const authContext = useContext(AuthContext)
    const { login, user, message, token, autenticado, usuarioAutenticado } = authContext;
    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        onSubmit: async (formData) =>
        {
            let data = {
                email: formData.email.trim(),
                password: md5(formData.password.trim())
            }
            await login(data);

        }
    })
    useEffect(() =>
    {
        usuarioAutenticado()
    }, [])

    useEffect(() =>
    {
        const roleRoutes = {
            ADMIN: '/admin',
            MANAGER: '/admin',
            WAITER: '/orders',
            CASHIER: '/orders',
            BAR: '/orders/bar',
            KITCHEN: '/orders/kitchen',
        };

        if (autenticado) {
            const userRoles = user?.role || [];

            for (const role in roleRoutes) {
                if (userRoles.includes(role)) {
                    navigate(roleRoutes[ role ]);
                    break;
                }
            }
        }
    }, [ token, autenticado, navigate ])


    useEffect(() =>
    {

        if (message) {
            // Context de error 
            showAlert(message.title, message.msg, message.categoria)
        }

    }, [ message ])

    return (
        <>
            <div className='w-full gap-1 h-[100vh] flex flex-col justify-center  overflow-x-auto  relative'>
                <Box className='flex flex-row absolute top-0 right-2'> <ToggleButton /></Box>

                <div className='flex flex-col  items-center justify-center w-full  gap-1 self-stretch'>

                    <Flex direction='column' justify="center" gap="md" w="100%" align="center">
                        <Card className='w-full md:w-8/12 lg:w-4/12 xl:w-3/12 ' shadow="lg" radius="xl" withBorder padding="xl">
                            <form onSubmit={ formik.handleSubmit }>
                                <Box className='flex flex-col items-center mb-2'>
                                    <img src={ Logo } alt='Logo' className='w-64 h-auto' />
                                    <Title order={ 4 } className='text-center'>Admin panel</Title>
                                </Box>

                                <TextInput
                                    label="Correo"
                                    size="lg"
                                    variant="filled"
                                    radius="md"
                                    mb='md'
                                    fullWidth
                                    placeholder="Correo"
                                    value={ formik.values.email }
                                    onChange={ (e) => formik.setFieldValue('email', e.target.value.toLowerCase()) }
                                />


                                <PasswordInput
                                    label="Contraseña"
                                    size="lg"
                                    variant="filled"
                                    radius="md"
                                    mb='md'
                                    fullWidth
                                    placeholder="Ingresa tu contraseña"
                                    value={ formik.values.password }
                                    onChange={ (e) => formik.setFieldValue('password', e.target.value) }
                                />



                                <Button autoContrast color='blue' variant='filled' radius="md" size="lg" fullWidth onClick={ () => formik.handleSubmit() }>Iniciar sesión</Button>

                            </form>

                            {/* <NavLink size="lg" color="blue" variant="filled" href={ `#/${ RESTORE_PASSWORD }` }
                                    label="Recuperar contraseña"
                                /> */}
                        </Card>
                    </Flex>


                </div>
                <Box className='absolute top-0 right-0 w-4/12'>
                    { alerta !== null ?
                        <Box className=''>

                            <Alert mx="lg" mt="sm" status={ alerta.categoria } variant="filled" color={ alerta.categoria === "error" ? 'red' : 'blue' } radius="lg" title="Ocurrio un error" icon={ <PiInfoDuotone className='text-3xl' /> }>
                                <Text>  { alerta.msg }</Text>
                            </Alert>
                        </Box>
                        : null }
                </Box>

            </div>

        </>
    )
}

export default Login

function initialValues ()
{
    return {
        email: '',
        password: ''
    }
}

function validationSchema ()
{
    return {
        email: Yup.string().required(true),
        password: Yup.string()
            .min(1, 'La contraseña debe de ser Mayor o igual a 6 caracteres')
            .max(18, 'La contraseña debe de ser menor o igual a 18 caracteres')
            .required(true)
    }
}